import React from 'react'
import { Helmet } from 'react-helmet'
import './all.scss'
import { withPrefix } from 'gatsby'
import { DocsPageWrapper, vectors, TrackingRootProvider } from "@minware/ui-minware";
import LinkType from './LinkType';

// Load this here so that it's included on all pages
import '../analytics';
import { trophy } from '@minware/ui-minware/dist-pkg/vectors';
import { track } from '../analytics';

const {
  graphLink,
  valueStream,
  codeSlash,magnifierBug,
  lineChart,
  listCheck,
  calendarDate,
  barChart,
  book,
} = vectors;



const Layout = ({ children, desktopBgImageName, leftMenu }) => {
  // WARNING: this must be kept in sync with app, which hosts survey pre-auth pages
  return (
    <TrackingRootProvider track={track}>
      <div>
        <Helmet>
          <html lang="en" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${withPrefix('/')}img/apple-touch-icon.png`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix('/')}img/favicon-32x32.png`}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix('/')}img/favicon-16x16.png`}
            sizes="16x16"
          />

          <link
            rel="mask-icon"
            href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
            color="#166df8"
          />
          <meta name="theme-color" content="#fff" />
        </Helmet>

        <DocsPageWrapper
          linkType={LinkType}
          onHamburgerClick={() => {
            alert('hambuger clicked');
          }}
          logoLink="/"
          loginLink="/api/auth/login"
          signUpLink="/api/auth/login?signup=1"
          leftMenu={leftMenu}
          navbarItems={[
            {
              label: 'Product',
              heading: 'Product',
              separator: true,
              items: [
                {
                  heading: 'Report Library',
                  separator: true,
                },
                {
                  label: 'Agile Scorecard',
                  link: '/report/sprint-best-practices',
                  icon: listCheck,
                },
                {
                  label: 'Predictive Roadmap',
                  link: '/report-types/predictive-roadmap',
                  icon: calendarDate,
                },
                {
                  label: 'Project Cost Allocation',
                  link: '/report/project-cost-allocation',
                  icon: barChart,
                },
                {
                  label: 'Browse All...',
                  link: '/reports',
                  icon: book,
                },
                {
                  heading: 'Observability Platform',
                  separator: true,
                },
                {
                  label: 'Code/Ticket Linking',
                  link: '/stack/code-ticket-linking',
                  icon: graphLink,
                },
                {
                  label: 'Work Timeline',
                  link: '/stack/time-attribution',
                  icon: valueStream,
                },
                {
                  label: 'Benchmarks',
                  link: '/stack/benchmarks',
                  icon: trophy,
                },
                {
                  label: 'Custom Report Builder',
                  link: '/org/demo/dashboard/ae061875-f72c-42a6-b999-ce54d9921df2',
                  icon: lineChart,
                },
              ],
            },
            {
              label: 'Pricing',
              link: '/pricing',
            },
            {
              label: 'Contact',
              link: '/contact',
            },
            {
              label: 'Resources',
              heading: 'Resources',
              separator: true,
              items: [
                {
                  label: 'Docs',
                  link: '/docs',
                },
                {
                  label: 'Security & Privacy',
                  link: '/docs/security',
                },
                {
                  label: 'About',
                  link: '/about',
                },
                {
                  label: 'Blog',
                  link: '/blog',
                },
              ],
            },
          ]}
          footerItems={[
            {
              label: 'Home',
              link: '/',
            },
            {
              label: 'Pricing',
              link: '/pricing',
            },
            {
              label: 'About',
              link: '/about',
            },
            {
              label: 'Contact',
              link: '/contact',
            },
            {
              label: 'Blog',
              link: '/blog',
            },
            {
              label: 'Documentation',
              link: '/docs',
            },
            {
              label: 'Security & Privacy',
              link: '/docs/security',
            },
            {
              label: 'Reports by Category',
              link: '/report-categories',
            },
            {
              label: 'Careers',
              link: '/careers',
            },
          ]}
          termsLink="/terms"
          privacyLink="/privacy"
          desktopBgImageName={
            desktopBgImageName === 'none' ? null :
            (desktopBgImageName || 'otherDesktop')
          }
        >
          {children}
        </DocsPageWrapper>
      </div>
    </TrackingRootProvider>
  )
}

export default Layout;
