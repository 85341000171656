import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import DocsPageLayout from '../components/DocsPageLayout';
import HeaderMeta from '../components/HeaderMeta';

import {
  Documentation,
  responsiveBreakpoints,
  TocLinks,
} from "@minware/ui-minware";

const twoColBreakpoint = responsiveBreakpoints.multiCol;
export const DocsPageTemplate = ({
  content,
  post,
  pathname,
  links,
}) => {

  const headerWithCanon = {
    ...content,
    canonicalUrl: pathname,
    title: `${content.title} | minware`,
  };

  return (
    <DocsPageLayout desktopBgImageName="none" leftMenu={<TocLinks links={links} currentUrl={pathname} breakpoint={twoColBreakpoint} />}>
      <HeaderMeta header={headerWithCanon}/>
      <Documentation links={links} currentUrl={pathname} body={post.html} isMediumText />
    </DocsPageLayout>
  )
}

DocsPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const buildHeadingLinks = (pageLink, headings) => {
  const baseList = [];
  const itemStack = [[1, baseList]]
  const processLinks = (heading) => {
    const headingDepth = heading.depth;
    let [checkDepth, checkList] = itemStack.at(-1);
    while (headingDepth <= checkDepth) {
      itemStack.pop();
      [checkDepth, checkList] = itemStack.at(-1);
    }
    const newItem = {
      id: heading.id,
      title: heading.value,
      link: `${pageLink}#${heading.id}`,
      parentLink: pageLink,
      children: [],
    };
    checkList.push(newItem);
    checkDepth = headingDepth;
    itemStack.push([checkDepth, newItem.children]);
  };
  headings.filter(({depth}) => depth !== 1).forEach(processLinks);

  return baseList
}

function buildLinkList(all, pathName) {
  // Extract flat list of item data
  const itemList = all.edges.map(edge => {
    const rv = {
      link: edge.node.fields.slug.replace(/\/$/, ''),
      parentLink: edge.node.frontmatter.parentSlug ? `/${edge.node.frontmatter.parentSlug}` : '/',
      title: edge.node.frontmatter.tocTitle ?? edge.node.frontmatter.title,
      order: edge.node.frontmatter.order ?? 0,
      children: [],
      headings: [],
    };
    if (pathName === rv.link) {
      rv.headings = buildHeadingLinks(rv.link, edge.node.headings);
    }

    return rv;
  });
  
  // Add root item
  itemList.push({
    link: '/',
    parentLink: null,
    children: [],
    headings: [],
  });
  // Add children to their parents to create a tree
  const itemMap = new Map();
  itemList.forEach(item => {
    itemMap.set(item.link, item);
  });
  itemList.forEach(item => {
    const { parentLink } = item;
    if (!parentLink) {
      return;
    }
    const parentItem = itemMap.get(parentLink);
    if (!parentItem) {
      throw new Error(`Parent link not found in docs: ${parentLink}`);
    }
    parentItem.headings.length = 0;
    parentItem.children.push(item);
    // Sort the children of each item
    parentItem.children.sort((a, b) => a.order - b.order);
  });
  // Return the root of the tree
  return itemMap.get('/').children;
}

const DocsPage = ({ data, location }) => {
  const { post, all } = data;
  const { pathname } = location;
  const stripSlashPath = pathname.length > 0 && pathname[pathname.length - 1] === '/'
    ? pathname.substr(0, pathname.length - 1)
    : pathname;

  const links = buildLinkList(all, stripSlashPath);

  const content = post.frontmatter;

  return (
    <DocsPageTemplate
      content={content}
      post={post}
      pathname={stripSlashPath}
      links={links}
    />
  )
}

DocsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default DocsPage

export const pageQuery = graphql`
  query DocsPageByID($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
      }
    }

    all: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "docs-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          headings {
            id
            value
            depth
          }
          frontmatter {
            tocTitle
            parentSlug
            order
          }
        }
      }
    }
  }
`
